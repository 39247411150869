
import router from '@/router';
import BoundingBox from '@/services/api/models/BoundingBox';
import { trackEvent } from '@/services/Mixpanel';
import ColumnOrderMoveEvent from '@/store/models/ColumnOrderMoveEvent';
import { Product } from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { isApp } from '@/utils/AppName';
import {
  adjustAndSetColumnOrder,
  orderColumnsBySavedOrder,
  removeSavedColumnOrder
} from '@/utils/ColumnOrdering';
import { Constants } from '@/utils/Constants';
import { groupProducts } from '@/utils/formatters/EntitiesFormatter';
import { goToSiteRequest } from '@/utils/FuelUtils';
import { getVisibleAppTableColumnFormatters } from '@/utils/GenericUtils';
import { setObjectItem } from '@/utils/LocalStorageUtils';
import { nestedEntitiesSorter, productsSorter } from '@/utils/SortingUtils';
import { milkSpecificTableColumnsFormatters } from '@/utils/table-fields/MilkTableFields';
import { liveSitesFields } from '@/utils/table-fields/TableFieldLists';
import { tableColumnFormatters } from '@/utils/table-fields/TableFields';
import { AllowedField, MilkAllowedField } from '@/utils/types/AllowedFields';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DraggablePanel from '../buttons/DraggablePanel.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import Searchbar from '../common/Searchbar.vue';
import ListView from '../list/ListView.vue';
import SiteCardList from '../list/SiteCardList.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  components: {
    DraggablePanel,
    ListView,
    SiteCardList,
    ScrollableContainer,
    Searchbar
  },
  methods: {
    goToSiteRequest
  },
  computed: {
    allProducts: () => ProductStore.currentProducts ?? [],
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token'
    })
  }
})
export default class LiveSites extends mixins(AppName, ConstantsMixin) {
  @Prop() isTable!: boolean;
  public boundingBox: BoundingBox = Constants.ALLPRODUCTBOUNDINGBOX;
  public products: Product[] = [];
  public activeSearch = false;
  public searchTerm = '';
  public loadingProducts = true;
  public tableColumns: any[] = [];
  public sortInfo: SortingInfo = {
    sortBy: null,
    increasing: true
  };
  public offset = 0;
  public perPageLimit = 100;

  get paginatedProducts() {
    return [...this.products].splice(this.offset, this.perPageLimit);
  }

  mounted() {
    this.fetchEntities(this.boundingBox);
    ProductStore.fetchAllEntityStates(this.getAppName(false));
    this.loadTableColumns();
  }

  created() {
    this.$root.$on('orgIndexChanged', () => {
      this.fetchEntities(this.boundingBox);
    });
    this.$root.$on('customFieldsUpdated', this.loadTableColumns);
    this.$root.$on('offsetChanged', this.changeOffset);
    this.$root.$on(
      'columnOrderChanged',
      (move: ColumnOrderMoveEvent, columns: any[]) => {
        this.tableColumns = columns;
        adjustAndSetColumnOrder(move);
      }
    );
  }

  public async loadTableColumns() {
    getVisibleAppTableColumnFormatters(liveSitesFields, false).then(columns => {
      this.tableColumns = orderColumnsBySavedOrder(columns);
      if (this.sortInfo.sortBy == null) {
        this.sortInfo.sortBy = isApp(this.PRODUCT_TYPE_MILK)
          ? milkSpecificTableColumnsFormatters[
              MilkAllowedField.SUPPLIER_NUMBER
            ] ?? null
          : tableColumnFormatters[AllowedField.SITE] ?? null;
      }
    });
  }

  private fetchEntities(boundingBox?: BoundingBox): void {
    this.loadingProducts = true;
    ProductStore.fetchEntityStates({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productRequest: {
        boundingBox,
        productType: this.getAppName(false)
      }
    }).then(async products => {
      const groupedByProductID = groupProducts(products);
      this.products = groupedByProductID;
      this.filterProducts();
      this.loadingProducts = false;
    });
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.toString();
    this.filterProducts();
  }

  public activateSearch() {
    this.activeSearch = true;
  }

  public deactivateSearch() {
    this.searchTerm = '';
    this.activeSearch = false;
    this.filterProducts();
  }

  public updateSortKey(newSortInfo: SortingInfo) {
    this.sortInfo = newSortInfo;
    trackEvent(`User updated ${this.getAppName(false)} sites sorting`, {
      sortBy: newSortInfo.sortBy?.name ?? ''
    });
    setObjectItem(`${this.getAppName(false)}SitesSortBy`, this.sortInfo);
    this.products = nestedEntitiesSorter([...this.products], this.sortInfo);
  }

  public changeOffset(isPrevious: boolean, exactPage: number) {
    if (isPrevious == null) {
      this.offset = exactPage * this.perPageLimit;
    } else {
      this.offset += isPrevious ? -this.perPageLimit : this.perPageLimit;
    }
  }

  public sortProducts(products: Product[]) {
    return productsSorter([...products], this.sortInfo);
  }

  public filterProducts(): void {
    // filter on search term
    let filtered = ProductStore.currentProducts;
    if (filtered) {
      filtered = filtered.filter(product => {
        return (
          product.siteName
            ?.toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) !== -1 ||
          (product.partnerDefinedId &&
            product.partnerDefinedId?.indexOf(this.searchTerm.toLowerCase()) !==
              -1)
        );
      });
      const groupedProducts = groupProducts(filtered);
      this.products = this.sortProducts(groupedProducts);
    }
  }
  public didSelectRow(item: Product, entityIndex: number) {
    router.push(`/${this.getAppName(false)}-sites/customer/${item.productId}`);
  }

  public resetColumnOrder(): void {
    removeSavedColumnOrder();
    this.loadTableColumns();
  }
}
