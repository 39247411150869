
import { Component, Ref, Watch, Vue } from 'vue-property-decorator';
import LiveSites from '@/components/sites/LiveSites.vue';
import { trackEvent } from '@/services/Mixpanel';

@Component({
  components: {
    LiveSites
  }
})
export default class SitesView extends Vue {
  public width = 1000;

  mounted() {
    trackEvent('User viewing milk sites');

    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }
}
