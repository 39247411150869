import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventFilterSiteRequests extends GAEvent {
  public eventName = 'filter-site-requests';
  public filters?: { [key: string]: any };

  constructor(filters?: { [key: string]: any }) {
    super();
    this.filters = filters;
  }
}
