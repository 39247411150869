
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({ components: { RepairIcon } })
export default class SiteCardList extends mixins(AppName) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() combinedValues!: Product[];
  @Prop() page!: string;
  @Prop() loadingProducts!: boolean;

  public didSelectCard(num: number, id: number) {
    this.$emit('card-selected', num, id);
  }
}
