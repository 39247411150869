
import RepairIcon from '@/components/common/RepairIcon.vue';
import StatusBadge from '@/components/common/StatusBadge.vue';
import router from '@/router';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({ components: { StatusBadge, RepairIcon } })
export default class RequestsCardList extends mixins(AppName) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() page!: string;
  @Prop() loading!: boolean;

  public getEntityLinkImage(image: string) {
    return require(`../../assets/${this.getAppName(false)}${image}.svg`);
  }

  public selectSite(id: number) {
    this.$emit('siteSelected', id);
  }
}
